<script lang="ts">
import anime from 'animejs'

import { PROJECT_HEADER_HEIGHT } from '~/app.vue'
import { BACKGROUND_MEDIA_CLASS } from '~/components/common/BackgroundMedia.vue'
import type { Project } from '~/types/project'

export const PROJECT_TRANSITION_BACKGROUND_CLASS = 'project-transition-background'
export const PROJECT_PREVIEW_TEXT_CLASS = 'project-preview-text'
</script>

<script lang="ts" setup>
const props = defineProps<{
  project: Project
  alignment: 'left' | 'right' | 'fluid'
}>()

const containerElement = ref<ComponentPublicInstance | null>(null)

const clipPathAnimation = reactive({
  topLeftYFactor: 1,
})

const transitioning = ref(false)

async function transition(href: string) {
  if (!containerElement.value?.$el) return
  if (transitioning.value) return

  // external link
  if (href.startsWith('http')) {
    return await navigateTo(href)
  }

  disablePageTransition.value = true

  transitioning.value = true

  const el = containerElement.value.$el as HTMLElement

  const { x, y, height, width } = el.getBoundingClientRect()

  const placeholder = document.createElement('div')

  placeholder.classList.add(...el.classList)
  placeholder.style.height = `${height}px`
  placeholder.style.transform = 'scaleY(5)'
  placeholder.style.width = '100%'
  placeholder.style.zIndex = '10'
  placeholder.style.position = 'relative'
  placeholder.style.backgroundColor = 'white'
  placeholder.style.marginTop = el.style.marginTop
  placeholder.style.marginBottom = el.style.marginBottom
  placeholder.style.marginLeft = el.style.marginLeft
  placeholder.style.marginRight = el.style.marginRight

  el.after(placeholder)

  el.style.transform = `translateX(${x}px) translateY(${y}px)`
  el.style.marginTop = '0'
  el.style.marginBottom = '0'
  el.style.zIndex = '20'
  el.style.position = 'fixed'
  el.style.height = `${height}px`
  el.style.width = `${width}px`
  el.style.clipPath = /* css */ `
    polygon(
      0 calc(var(--tilt-height-container)*var(--top-left-y-factor,1)),
      100% 0,
      100% calc(100% - (
        /* transition from --tilt-height-container -> --tilt-height-fluid */
        (     var(--top-left-y-factor, 1)  * var(--tilt-height-container)) +
        ((1 - var(--top-left-y-factor, 1)) * var(--tilt-height-fluid))
      )),
      0 100%)
    `

  const topLeftYFactorCssVar = useCssVar('--top-left-y-factor', containerElement)
  watchEffect(() => {
    topLeftYFactorCssVar.value = `${clipPathAnimation.topLeftYFactor}`
  })

  document.body.append(el)

  preloadRouteComponents(href)

  const duration = 700

  anime({
    targets: el.querySelector(`.${PROJECT_PREVIEW_TEXT_CLASS}`),
    translateX: [0, props.alignment === 'left' ? '200%' : '-200%'],
    easing: 'easeInOutCubic',
    duration,
  })

  anime({
    targets: el.querySelector(`.${BACKGROUND_MEDIA_CLASS}`),
    width: '100%',
    left: 0,
    easing: 'easeInOutCubic',
    duration,
  })

  anime({
    targets: placeholder,
    opacity: [0, 1],
    duration: duration * 0.7,
    easing: 'linear',
  })

  anime({
    // animate project header image
    targets: [el, clipPathAnimation],
    translateX: [x, 0],
    translateY: [y, 0],
    width: [width, document.body.clientWidth],
    height: PROJECT_HEADER_HEIGHT.value,
    duration,
    topLeftYFactor: [1, 0],
    easing: 'easeInOutCubic',
    endDelay: 50,

    complete: async () => {
      el.style.transition = 'height ease-in-out 1s'

      const nuxt = useNuxtApp()
      nuxt.hooks.hookOnce('page:finish', () => {
        console.debug('page:finish')
        disablePageTransition.value = false
      })

      await navigateTo(href)

      if (el.parentElement) el.parentElement.style.backgroundColor = ''
      el.style.height = '100%'
      el.style.width = '100%'

      // scroll to top inside BackgroundMedia.vue
    },
  })
}
</script>

<template>
  <Tilt
    ref="containerElement"
    contain
    :class="[PROJECT_TRANSITION_BACKGROUND_CLASS]"
    class="relative left-0 top-0 h-[clamp(18rem,60vw,max(40vh,50rem))]"
    :style="{
      '--color-primary': project.primary_color,
      '--color-secondary': project.secondary_color,
    }"
  >
    <ElbworxLink v-slot="{ href }" custom :href="`/projekt/${project.slug}`">
      <a :href="href" @click.prevent="() => transition(href)">
        <BackgroundMedia
          :background-color="project.primary_color"
          :background-image="project.header.background.image"
          :background-video="project.header.background.video"
          :background-position="project.header.background.position"
          :black-tint="!project.header.background.black_tint_off"
          :class="[
            alignment === 'left' && 'right-auto',
            alignment === 'right' && 'left-auto',
            alignment === 'fluid' ? 'w-full' : 'w-[64%] md:w-[58%] 2xl:w-[54%]',
          ]"
        />
      </a>
    </ElbworxLink>
    <Padding
      class="pointer-events-none relative flex h-full items-center md:items-end"
      :class="[
        alignment === 'left' && 'justify-end text-end',
        alignment === 'fluid' && 'text-white',
        // ? 'text-white [text-shadow:rgba(0,0,0,.5)_0px_0px_1rem]'
        // : '[text-shadow:rgba(255,255,255,.5)_0px_0px_1rem,rgba(255,255,255,.5)_0px_0px_3rem,rgba(255,255,255,.75)_0px_0px_5rem]',
      ]"
    >
      <div
        class="pointer-events-auto w-1/2"
        :class="[
          PROJECT_PREVIEW_TEXT_CLASS,
          alignment === 'right' && 'md:-mb-4',
          alignment === 'left' ? '2xl:mr-48' : '2xl:ml-48',
        ]"
      >
        <ElbworxLink v-slot="{ href }" custom :href="`/projekt/${project.slug}`">
          <div class="flex flex-col" :class="[alignment === 'left' && 'items-end']">
            <a :href="href" @click.prevent="() => transition(href)">
              <h3
                class="font-black italic md:text-xl xl:text-3xl"
                :class="[project.header.project_class]"
              >
                {{ project.header.project }}
              </h3>
              <h2
                class="text-2xl font-extrabold italic tracking-tight sm:-ml-1 sm:mt-2 sm:text-4xl md:text-[52px]/[1] xl:text-[80px]/[1]"
                :class="[
                  project.header.headline_class,
                  project.header.headline_low_contrast_fix &&
                    '[text-shadow:0_0_0.25rem_rgba(255,255,255,0.9)]',
                ]"
                v-html="project.header.headline"
              />
            </a>
            <div class="mt-2 flex gap-6 [text-shadow:none] md:mt-6 xl:mt-8">
              <a :href="href" @click.prevent="() => transition(href)">
                <div
                  class="w-fit bg-elbworx-black px-2 pt-1 text-center font-mono text-white md:text-sm xl:text-base"
                >
                  Projektdetails
                </div>
              </a>
              <ElbworxLink
                v-if="project.header.info_card.project_url"
                :href="project.header.info_card.project_url"
                class="hidden h-fit px-2 pt-1 font-mono font-semibold decoration-2 underline-offset-2 hover:underline focus:underline lg:inline"
                external
                target="_blank"
              >
                Zur Website
              </ElbworxLink>
            </div>
          </div>
        </ElbworxLink>
      </div>
    </Padding>
  </Tilt>
</template>
